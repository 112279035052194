// src/utils/analytics.js

import { ANALYTICS_CONFIG } from '../config/analytics';

// Initialize analytics configuration
export const initializeAnalytics = () => {
  if (typeof window.gtag !== 'undefined') {
    debugGAConfig();
    // Configure cross-domain tracking
    window.gtag('config', ANALYTICS_CONFIG.measurementId, {
      debug_mode: ANALYTICS_CONFIG.debug,
      linker: {
        domains: ANALYTICS_CONFIG.domains,
        accept_incoming: true,
        decorate_forms: true,
        use_anchor: false,
        enhanced_measurement: true
      },
      cookie_flags: ANALYTICS_CONFIG.cookieFlags,
      cookie_domain: 'auto',
      allow_linker: true
    });

    // Enable auto-linking with enhanced settings
    window.gtag('linker:autoLink', ANALYTICS_CONFIG.domains, {
      accept_incoming: true,
      use_anchor: false,
      enhanced_measurement: true
    });
  }
};

// Enhanced cross-domain navigation handler
export const handleCrossDomainNavigation = (url) => {
  if (!url) return Promise.reject(new Error('No URL provided'));
  
  // console.log('Analytics: Starting cross-domain navigation to:', url);
  
  return new Promise((resolve, reject) => {
    let decorationAttempts = 0;
    const maxAttempts = 3;
    const timeout = setTimeout(() => {
      reject(new Error('URL decoration timeout'));
    }, 2000); // 2 second timeout

    const attemptDecoration = () => {
      // console.log(`Analytics: Decoration attempt ${decorationAttempts + 1}`);
      
      try {
        window.gtag('linker:decorate', {
          url: url,
          success: (decoratedUrl) => {
            clearTimeout(timeout);
            if (decoratedUrl.includes('_gl=')) {
              // console.log('Analytics: URL decorated successfully:', {
              //   original: url,
              //   decorated: decoratedUrl,
              //   params: new URL(decoratedUrl).searchParams.toString()
              // });
              resolve(decoratedUrl);
            } else if (decorationAttempts < maxAttempts) {
              decorationAttempts++;
              setTimeout(attemptDecoration, 200);
            } else {
              // console.warn('Analytics: Max decoration attempts reached');
              trackCrossDomainError(new Error('URL decoration failed'), url);
              resolve(url); // Fallback to original URL
            }
          },
          error: (err) => {
            // console.error('Analytics: URL decoration failed:', err);
            if (decorationAttempts < maxAttempts) {
              decorationAttempts++;
              setTimeout(attemptDecoration, 200);
            } else {
              clearTimeout(timeout);
              trackCrossDomainError(err, url);
              resolve(url); // Fallback to original URL
            }
          }
        });

        // Send outbound click event
        window.gtag('event', 'outbound_click', {
          send_to: ANALYTICS_CONFIG.measurementId,
          link_url: url,
          transport_type: 'beacon',
          event_category: 'Cross Domain',
          event_label: new URL(url).hostname
        });
      } catch (error) {
        clearTimeout(timeout);
        // console.error('Analytics: Error during decoration:', error);
        trackCrossDomainError(error, url);
        resolve(url);
      }
    };

    // Ensure GA is loaded before attempting decoration
    if (typeof window.gtag === 'undefined') {
      // console.error('Analytics: gtag not loaded');
      resolve(url);
      return;
    }

    // Start decoration attempts
    attemptDecoration();
  });
};

// Track events with domain information
export const trackEvent = (eventName, eventParams = {}) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('event', eventName, {
      ...eventParams,
      send_to: ANALYTICS_CONFIG.measurementId,
      source_domain: window.location.hostname
    });
  }
};

// Add this function
export const toggleDebugMode = (enable = true) => {
  if (typeof window.gtag !== 'undefined') {
    window.gtag('config', ANALYTICS_CONFIG.measurementId, {
      debug_mode: enable
    });
    // console.log(`GA4 debug mode ${enable ? 'enabled' : 'disabled'}`);
  }
};

// Add this function
export const verifyCrossDomainTracking = () => {
  const params = new URLSearchParams(window.location.search);
  const glParam = params.get('_gl');
  const gaParam = params.get('_ga');
  
  // console.log('Cross-domain parameters:', {
  //   _gl: glParam,
  //   _ga: gaParam,
  //   referrer: document.referrer,
  //   hostname: window.location.hostname
  // });
  
  return {
    hasGlParam: !!glParam,
    hasGaParam: !!gaParam,
    params: {
      _gl: glParam,
      _ga: gaParam
    }
  };
};

// Add this function
export const trackCrossDomainError = (error, destination) => {
  if (typeof window.gtag !== 'undefined') {
    // console.error('trackCrossDomainError:', {
    //   error: error.message,
    //   destination: destination,
    //   source: window.location.hostname
    // });
    window.gtag('event', 'cross_domain_error', {
      error_message: error.message,
      destination_domain: destination,
      source_domain: window.location.hostname
    });
  }
};

// Add this function to check GA configuration
export const debugGAConfig = () => {
  // console.group('GA Configuration Debug');
  // console.log('GA Available:', typeof window.gtag !== 'undefined');
  // console.log('Measurement ID:', ANALYTICS_CONFIG.measurementId);
  // console.log('Allowed Domains:', ANALYTICS_CONFIG.domains);
  // console.log('Current Domain:', window.location.hostname);
  // console.log('Debug Mode:', ANALYTICS_CONFIG.debug);
  // console.groupEnd();
};

// Add to analytics.js
export const trackCrossDomainNavigation = (destination, linkLocation) => {
  const category = destination.includes('csdlc.org') ? 
    ANALYTICS_CONFIG.crossDomainCategories.CSDLC : 
    ANALYTICS_CONFIG.crossDomainCategories.AISDLC;

  gtag('event', 'cross_domain_navigation', {
    destination_domain: destination,
    source_domain: window.location.hostname,
    link_location: linkLocation,
    category: category,
    user_journey: `${window.location.hostname}_to_${destination}`
  });
};

export const trackPageView = (pageTitle, pageLocation) => {
  gtag('event', 'page_view', {
    page_title: pageTitle,
    page_location: pageLocation,
    source_domain: window.location.hostname
  });
};

export const trackUserEngagement = (duration) => {
  gtag('event', 'user_engagement', {
    engagement_time_msec: duration,
    source_domain: window.location.hostname
  });
};