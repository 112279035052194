// src/config/analytics.js
export const ANALYTICS_CONFIG = {
    measurementId: 'G-JSXPY1887B',
    domains: [
      'vaidhyamegha.com',
      'dev-vaidhyamegha.web.app',
      'csdlc.org',
      'aisdlc.com'
    ],
    cookieFlags: 'SameSite=None;Secure',
    debug: true,
    allowLinker: true,
    crossDomainCategories: {
      CSDLC: 'csdlc_interaction',
      AISDLC: 'aisdlc_interaction'
    }
  };